import { Controller } from '@hotwired/stimulus';
import debounce from '../support/debounce';

/*
 * Submit a form while the user is typing in a search field.
 *
 * Usage:
 * 1. Create a form and attach to this controller by setting the attribute
 *    `data-controller="searchfield"`
 * 2. Create a search field and set the attribute
 *    `data-action="input->searchfield#processSearchInput"`
 */
export default class extends Controller {
  connect() {
    this.processSearchInput = debounce(() => this.submit());
  }

  submit(event) {
    if (event) { event.preventDefault(); }
    this.element.requestSubmit();
  }
}
