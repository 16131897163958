import { Controller } from '@hotwired/stimulus';
import { marked } from 'marked';
import DOMPurify from 'isomorphic-dompurify';

// Connects to data-controller="rich-text"
export default class extends Controller {
  static targets = [
    'writeButton',
    'previewButton',
    'input',
    'preview',
    'menu',
  ];

  connect() {
    this.toggleElements(false);
  }

  preview() {
    const markdown = this.inputTarget.getElementsByTagName('textarea')[0].value;
    this.previewTarget.getElementsByClassName('markdown')[0].innerHTML = DOMPurify.sanitize(marked.parse(markdown));
    this.toggleElements(true);
  }

  write() {
    this.toggleElements(false);
  }

  toggleElements(preview) {
    const activeButtonClasses = ['bg-primary-100', 'text-slate-900', 'hover:bg-primary-200'];
    const inactiveButtonClasses = ['bg-white', 'text-slate-500', 'hover:bg-slate-100', 'hover:text-slate-900'];

    this.toggleCSSclasses(this.previewButtonTarget, !preview, inactiveButtonClasses);
    this.toggleCSSclasses(this.previewButtonTarget, preview, activeButtonClasses);

    this.toggleCSSclasses(this.writeButtonTarget, preview, inactiveButtonClasses);
    this.toggleCSSclasses(this.writeButtonTarget, !preview, activeButtonClasses);

    this.menuTarget.classList.toggle('hidden', preview);
    this.inputTarget.classList.toggle('hidden', preview);
    this.previewTarget.classList.toggle('hidden', !preview);
  }

  // eslint-disable-next-line class-methods-use-this
  toggleCSSclasses = (element, active, classes) => classes.map((cl) => element.classList.toggle(cl, active));
}
